<template>
  <div class="bg-shop-summary">
    <el-card shadow="hover" class="x-card-title-slot mb-3">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          总流量数据
        </div>
        <div class="col-auto">起止日期：</div>
        <div class="col-auto mr-3">
          <el-date-picker
            style="width: 220px"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            v-model="daterangeDate"
            type="daterange"
            @change="daterangeDateChange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="medium"
            :picker-options="pickerOptions"
          />
        </div>

        <el-button type="primary" size="small" @click="handleSearch()">
          搜索
        </el-button>

        <div class="col"></div>

        <el-button type="text" :loading="importLoading" @click="downZd()">导出当前数据</el-button>
      </div>

      <div id="priceList" :style="{ height: '60vh' }"></div>
    </el-card>

    <el-card shadow="hover" class="x-card-title-slot mb-3">
      <div class="row no-gutters align-items-center" slot="header">
        <div class="col-auto header-title">
          TOP流量数据
        </div>
        <div class="col-auto">起止日期：</div>
        <div class="col-auto mr-3">
          <el-date-picker
            style="width: 220px"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            v-model="daterangeDate"
            type="daterange"
            size="medium"
            @change="daterangeDateChange1"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <el-button type="primary" size="small" @click="handleSearch1()">
          搜索
        </el-button>
      </div>

      <div class="row no-gutters align-items-center" style="height: 60vh">
        <div class="col" style="height: 100%;width:45%">
          <div id="topPie" style="height: 100%"></div>
        </div>
        <div class="col" style="height: 100%;width:45%">
          <en-table-layout :toolbar="false" border :tableData="pageData.data">
            <template slot="table-columns">

              <el-table-column label="排行">
                <template slot-scope="scope">{{ scope.$index + 1 }}</template>
              </el-table-column>

              <el-table-column
                width="200px"
                prop="goods_name"
                label="名称"
              />

              <el-table-column prop="buy_count" label="销量"></el-table-column>

              <el-table-column prop="num" label="PV"></el-table-column>
            </template>
          </en-table-layout>
        </div>
      </div>
    </el-card>

  </div>
</template>

<script>
import * as API_buyAnyalysis from '@/api/buyAnyalysis';
import {CategoryPicker} from '@/components';
import {Foundation} from '@/../ui-utils';
import {handleDownload} from '@/utils';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';

export default {
  name: 'buyAnalysis',
  components: {
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker
  },
  data() {
    return {
      type: 1,
      pageData: {data: []},
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 0);
              start.setTime(start.getTime());
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近7日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近15日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '近30日',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      /** 列表loading状态 */
      loading: false,
      importLoading: false,

      daterangeDate: '',

      /** 图表参数*/
      params: {},
      params1: {},
      data: {},
      data1: {},

      tableHeight: document.body.clientHeight / 2
    };
  },
  watch: {
    daterangeDate(value) {
      // console.log(value);
    }
  },
  mounted() {
    window.onresize = this.countTableHeight;
    this.$nextTick(() => {
      this.priceListChart = this.$echarts.init(document.getElementById('priceList'));
      this.topPie = this.$echarts.init(document.getElementById('topPie'));

      this.resetTimeParams();

      this.GET_PriceStatistics();
      this.GET_PriceStatistics1();
    });
  },
  methods: {
    resetTimeParams(type) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);

      if (type === 1 || type === undefined) {
        this.params.start_time = parseInt(start / 1000);
        this.params.end_time = parseInt(end / 1000);
      }

      if (type === 2 || type === undefined) {
        this.params1.start_time = parseInt(start / 1000);
        this.params1.end_time = parseInt(end / 1000);
      }
    },
    downZd(row) {
      let data = {...this.data};
      let data1 = {...this.data1};
      this.importLoading = true;
      let response = [];
      data.xAxis.forEach(item => {
        response.push({date: item});
      });
      data.series.data.forEach((item, index) => {
        response[index]['PV'] = item;
      });
      data1.series.data.forEach((item, index) => {
        response[index]['UV'] = item;
      });
      let tHeaders = ['日期', 'PV', 'UV'];
      let filterVals = ['date', 'PV', 'UV'];
      handleDownload(response, tHeaders, filterVals, '总流量数据');
      this.importLoading = false;
    },
    daterangeDateChange(val) {
      if (val) {
        this.params.start_time = parseInt(val[0] / 1000);
        this.params.end_time = parseInt(val[1] / 1000);
      } else {
        this.resetTimeParams(1);
      }
      this.GET_PriceStatistics();
    },
    daterangeDateChange1(val) {
      if (val) {
        this.params1.start_time = parseInt(val[0] / 1000);
        this.params1.end_time = parseInt(val[1] / 1000);
      } else {
        this.resetTimeParams(2);
      }
      this.GET_PriceStatistics1();
    },
    /** 窗口缩放时计算table高度 */
    countTableHeight() {
      this.tableHeight = document.body.clientHeight / 2;
      /** 图表刷新 */
      setTimeout(this.priceListChart.resize);
      setTimeout(this.topPie.resize);
    },

    /** 执行搜索 */
    handleSearch() {
      this.GET_PriceStatistics();
    },
    /** 执行搜索 */
    handleSearch1() {
      this.GET_PriceStatistics1();
    },
    GET_PriceStatistics() {
      API_buyAnyalysis.page_viewShopPV(this.params).then(response => {
        API_buyAnyalysis.page_viewShopUV(this.params).then(res => {
          this.data = response;
          this.data1 = res;

          this.priceListChart.setOption({
            tooltip: {trigger: 'axis'},
            grid: {
              x: '50px',
              y: '80px',
              x2: '60px',
              y2: '30px'
            },
            legend: {
              show: true,
              data: [{name: 'PV'}, {name: 'UV'}],
              top: '10px'
            },
            toolbox: {
              feature: {
                magicType: {type: ['line', 'bar']},
                restore: {},
                saveAsImage: {}
              }
            },
            xAxis: {
              name: '日期',
              type: 'category',
              boundaryGap: false,
              data: response.xAxis
            },
            yAxis: {
              name: '流量',
              type: 'value',
              axisLabel: {
                formatter: '{value}'
              }
            },
            series: [
              {
                type: 'line',
                data: response.series.data,
                name: 'PV',
                barWidth: 50,
                smooth: true,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 2,
                      color: '#1a43a9'
                    },
                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#c2bbfe'
                      },
                      {
                        offset: 1,
                        color: '#d3edfb'
                      }
                    ])
                  }
                },
                areaStyle: {normal: {}},
                symbolSize: 0
              },
              {
                type: 'line',
                name: 'UV',
                data: res.series.data,
                barWidth: 50,

                smooth: true,
                itemStyle: {
                  normal: {
                    lineStyle: {
                      width: 2,
                      color: '#4fa736'
                    },
                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: '#acd9a9'
                      },
                      {
                        offset: 1,
                        color: '#d0eccd'
                      }
                    ])
                  }
                },
                areaStyle: {normal: {}},
                symbolSize: 0
              }
            ]
          });
        });
      });
    },
    GET_PriceStatistics1() {
      API_buyAnyalysis.page_viewGoodsPV(this.params1).then(res => {
        if (!res.length) {
          res = [{
            num: 0,
            buy_count: '-',
            goods_name: '暂无数据'
          }];
        }

        res.forEach(item => {
          item.value = item.num;
          item.name = item.goods_name;
        });

        this.pageData.data = res;
        this.topPie.setOption({
          tooltip: {
            trigger: 'item',
            // formatter: '{a} <br/>{b} : {c} ({d}%)',
            formatter: function (params111) {
              return (
                '<div style = "text-align:center;width:200px;white-space:normal">' +
                params111.seriesName +
                '<br/>' +
                params111.name +
                ' : ' +
                params111.value +
                '(' +
                params111.percent +
                '%)</div>'
              );
            },
            textStyle: {
              width: '80px',
              fontSize: 12
            }
          },
          series: [
            {
              name: 'TOP流量数据',
              type: 'pie',
              avoidLabelOverlap: true,
              radius: '60%',
              center: ['50%', '50%'],
              label: {
                show: false,
                normal: {
                  formatter(v) {
                    let name = v.name;
                    if (name.length <= 14) {
                      return name;
                    } else {
                      name = name.slice(0, 14) + '...';
                      return name;
                    }
                  },
                  textStyle: {
                    fontSize: 12
                  }
                }
              },
              data: this.pageData.data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>

